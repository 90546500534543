#checkoutForm{padding:20px;}

.flex-50{flex: 1 0;}
.flex-75{flex: 2 75%;}
.flex-25{flex: 1 0;}

.stripe-con{border-bottom: 2px solid #fff !important;padding-bottom:4px;}
.current-price{ font-size:2rem; }
.small{font-size: .85rem;}
.smaller{font-size: .7rem;}





input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px rgba(0,0,0,1) inset;
  transition: background-color 5000s ease-in-out 0s;
}